import * as React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Document, Page ,pdfjs} from "react-pdf";
import ShortTextIcon from '@mui/icons-material/ShortText';
import { useState } from "react";
import { useEffect } from "react";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Draggable from 'react-draggable';
import _cloneDeep from 'lodash/cloneDeep';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import { TextField,MenuList, MenuItem, Popper, Grow, Paper, ClickAwayListener, Button, Avatar, Input, Box, Grid, CssBaseline, createTheme, ThemeProvider, ListItem, ListItemAvatar, ListItemText, IconButton, List, ListItemSecondaryAction } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import WcIcon from '@mui/icons-material/Wc';
import { useCallback } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "@fontsource/roboto-mono";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DrawIcon from '@mui/icons-material/Draw';
import { send ,getDefaultLabel,getCurrentLabel} from "./helper";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    canvasContainer: {
      position: "relative",
      "& canvas": {
        position: "absolute",
        top: 0,
        left: 0,
      },
    },
  }));

  

  
  


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Side() {
    const [key, setKey] = useState(0);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(0);
    const [pagesize, setpagesize] = useState([0,0,0]);
    const [pdfObject, setpdfObject] = useState();
    const [draggableobject, setdraggableobject] = useState([]);
    const [draggableobjectlist, setdraggableobjectlist] = useState({});
    const [draggableobjectposition, setdraggableobjectposition] = useState([]);
    useEffect(() => {
      setKey(key+1);
    },[draggableobjectlist,pageNumber,draggableobject]);
    let icon={
      "Name": <ShortTextIcon/>,
      "Id card number":<ShortTextIcon/>,
      "Passport number":<ShortTextIcon/>,
      "Address":<ShortTextIcon/>,
      "Gender":<WcIcon/>,
      "Text":<ShortTextIcon/>,
      "TextBox":<TextFieldsIcon/>,
      "Date":<CalendarMonthIcon/>,
      "tickbox":<CheckBoxIcon/>,
      "cross":<CancelIcon/>,
      "Signature":<DrawIcon/>,
    }
    const [draggableobjectlistoption, setdraggableobjectlistoption] = useState({
      "Name": [],
      "Id card number":[],
      "Passport number":[],
      "Address":[],
      "Gender":[],
      "Text":[],
      "TextBox":[],
      "Date":[],
      "tickbox":[],
      "cross":[],
      "Signature":[],
    });
    useEffect(() => {
      getDefaultLabel().then((data)=>{
      let temp={};
      let keys=Object.keys(data['body']);

      for(let i=0;i<keys.length;i++){
        temp[keys[i]]=[];
        for(let j=0;j<data['body'][keys[i]].length;j++){
          temp[keys[i]].push({
            inputValue: data['body'][keys[i]][j],
            title: data['body'][keys[i]][j],
          });
        }
      }
      setdraggableobjectlistoption(temp);
      })
    },[]);
    const [anchorEl, setAnchorEl] = React.useState(null)
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
  
    const handleToggle = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget)
      setOpen(!anchorEl)
    };
  
    const handleClose = (event) => {
      event.stopPropagation();
      if (anchorEl && anchorEl.contains(event.target)) {
        return
      }
      setOpen(false)
      setAnchorEl(null)
    };
  
    
    

  
    const onDocumentLoadSuccess = (pdfobject) => {
      setPageNumber(1);
      setpdfObject(pdfobject);
      let temp={}
      console.log(pdfobject.numPages);
      for(let i=1;i<=pdfobject.numPages;i++){
        console.log(i);
        temp[i]={          
          "label":[],
        }
      }
      pdfobject?.getPage(1).then((p)=>{
        let ratio=document.getElementsByClassName('PdfDiv')[0]?.clientWidth/p.view[2]
        let tuple=[p.view[2]*ratio,p.view[3]*ratio,ratio]
        temp[1]["pdf_width"]=tuple[0];
        temp[1]["pdf_height"]=tuple[1];
        temp[1]["scale"]=tuple[2];
      })
      getCurrentLabel(file.name).then((data)=>{
        if (data['body']&&Object.keys(data['body']).length>0){
          temp={...temp,...data['body']};
        }
        console.log(temp);
        setdraggableobjectlist(temp);
      })
      
      setNumPages(pdfobject.numPages);
    };
    useEffect(() => {
      pdfObject?.getPage(pageNumber).then((p)=>{
        let ratio=document.getElementsByClassName('PdfDiv')[0]?.clientWidth/p.view[2]
        let tuple=[p.view[2]*ratio,p.view[3]*ratio,ratio]
        setpagesize(tuple);
      })
    }, [numPages,pdfObject,document.getElementsByClassName('PdfDiv')[0]?.clientWidth,pageNumber]);
   

  
    useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;});
    const [file, setFile] = useState(null);
    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
      };
      const [isDragging, setIsDragging] = useState(false);
      
        function handleDragStart() {
          setIsDragging(true);
        }
        
        useEffect(() => {
          if (draggableobjectposition.length === 0) return;
          setdraggableobjectlist((prev) => {
            let temp=_cloneDeep(prev);
            temp[pageNumber]['label'][draggableobjectposition[0]][2]=draggableobjectposition[1];
            return temp;
        })}, [draggableobjectposition]);

        const handleDragStop = useCallback((event, ui, index) => {
          let position = [ui.x, ui.y, ui.node.clientWidth, ui.node.clientHeight];
          //normalize position to pdf size
          position[0]=position[0]/pagesize[0];
          position[1]=position[1]/pagesize[1];
          setdraggableobjectposition([index,position]);
          setIsDragging(false);
        }, [draggableobjectlist]);

      function PDFViewer() {
        
        let ref=React.createRef();
        return (
          <Box style={{ position: 'relative'}}>
            {file && (
              <Box className="PdfDiv" width={'800px'} height={'100%'} ref={ref} >
                <Document file={file} onLoadSuccess={onDocumentLoadSuccess} >
                  <Page
                    pageNumber={pageNumber}
                    className={classes.canvasContainer}
                    // onClick={handlePageClick}
                    width={document.getElementsByClassName('PdfDiv')[0]?.clientWidth*1 ?? 150}
                  ></Page>
                </Document>
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: String(pagesize[0])+'px',
                    maxWidth: 'inherit',
                    height: String(pagesize[1])+'px',
                    backgroundColor: 'transparent',
                    zIndex: 10,
                    pointerEvents: isDragging ? 'none' : 'auto',
                  }}
                  key={key}
                >
                  {
                  draggableobject}
                  
                </div>
              </Box>
            )}
          </Box>
        );
      }
      function objectlist(){
        let temp=[];
        
        if (draggableobjectlist[pageNumber])
        for(let i=0;i<draggableobjectlist[pageNumber]['label'].length;i++){
          
          temp.push(<ListItem
        
        fullWidth={true}
        secondaryAction={
          <Box display="flex" flexDirection="row" justifyContent="center">
          <Box width="4rem">
          <TextField label="Font Width/Size" variant="standard" size="small"
               type="number" defaultValue={draggableobjectlist[pageNumber]['label'][i][3]} onChange={(event)=>{
                  
                  let fontwidth=event.target.value;
                  let temp=_cloneDeep(draggableobjectlist);
                  temp[pageNumber]['label'][i][3]=fontwidth;
                  setdraggableobjectlist(temp);
               }}/>
          </Box>
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon onClick={()=>{
              let temp=_cloneDeep(draggableobjectlist);
              temp[pageNumber]['label'].splice(i,1);
              setdraggableobjectlist(temp);
            }}/>
          </IconButton>
          </Box>
        }
      >
        <ListItemAvatar>
          <Avatar>
            {icon[draggableobjectlist[pageNumber]['label'][i][0]]}
          </Avatar>
        </ListItemAvatar>
         
        <ListItemText
          primary=  <Autocomplete
          value={draggableobjectlist[pageNumber]['label'][i][1]}
          onChange={(event, newValue) => {
            if (newValue&&'inputValue' in newValue){
              let temp=_cloneDeep(draggableobjectlistoption);
              if (!temp[draggableobjectlist[pageNumber]['label'][i][0]].find((element)=>{return element.inputValue===newValue.inputValue})){

              let temp=_cloneDeep(draggableobjectlistoption);
              temp[draggableobjectlist[pageNumber]['label'][i][0]].push({
                inputValue: newValue.inputValue,
                title: newValue.inputValue,
              });
              setdraggableobjectlistoption(temp);
            }
          
            let tempa=_cloneDeep(draggableobjectlist);
            tempa[pageNumber]['label'][i][1]=newValue.inputValue;
            setdraggableobjectlist(tempa);
          
          }
            
          }}
          filterOptions={(options, params) => {
            const filtered = options.filter((option) => {
              return option.inputValue.toLowerCase().includes(params.inputValue.toLowerCase())
            });
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
              });
            }
  
            return filtered;
          }}
          options={draggableobjectlistoption[draggableobjectlist[pageNumber]['label'][i][0]]}
          isOptionEqualToValue={(option, value) => {
            return option.inputValue == value
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option
            }
            return option.inputValue
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          sx={{ width: 300 }}
          
          renderInput={(params) => <TextField {...params} label={draggableobjectlist[pageNumber]['label'][i][0]} />}
        />
          
          
          
          
          secondary={`Id: ${i} ,`+ String(draggableobjectlist[pageNumber]['label'][i][0])}
        />
        
      </ListItem>)
        }
        return temp;
      }
    const add_draggableObject=(type)=>{
      
      let tempp=_cloneDeep(draggableobjectlist);
      tempp[pageNumber]['label'].push([type,"",[0,0,0,0],['tickbox','cross','Signature'].includes(type)?12:2]) //type,text,position
      setdraggableobjectlist(tempp);
    }
    useEffect(() => {
      let temp=[];
      let tempobject=_cloneDeep(draggableobjectlist);
      if (tempobject[pageNumber])
      for(let i=0;i<tempobject[pageNumber]['label'].length;i++){
        temp.push(
      <Draggable id={"draggable"+String(i)} 
      onStart={handleDragStart} 
      onStop={(event, ui)=>{handleDragStop(event, ui,i)}} bounds="parent"
      defaultPosition={{x: tempobject[pageNumber]['label'][i][2][0]*pagesize[0], y: tempobject[pageNumber]['label'][i][2][1]*pagesize[1]}}
      >
                    <Box
                      style={{
                        width: "max-content",
                        backgroundColor: "rgba(200,0,0,0.2)",
                        borderRadius: 5,
                        textAlign: 'center',
                        fontSize: ['tickbox','cross','Signature'].includes(tempobject[pageNumber]['label'][i][0])?String(pagesize[2]*tempobject[pageNumber]['label'][i][3])+'px':"12px",
                        wordSpacing: 0,
                        fontFamily: 'Roboto Mono',
                        letterSpacing: ['tickbox','cross','Signature'].includes(tempobject[pageNumber]['label'][i][0])?"2px":String(pagesize[2]*tempobject[pageNumber]['label'][i][3])+'px',
                      }}
                    >
                      {i} {["tickbox","cross"].includes(tempobject[pageNumber]['label'][i][0])?"":["Id card number"].includes(tempobject[pageNumber]['label'][i][0])?"A1234567":["Gender"].includes(tempobject[pageNumber]['label'][i][0])?"Male":"Text Example"}
                    </Box>
        </Draggable>)
      }
      console.log(pageNumber);
      console.log(draggableobjectlist);
      setdraggableobject(temp);

    }, [draggableobjectlist,pagesize,pageNumber]);
    useEffect(() => {
      if (pagesize[0] && pagesize[1] && pagesize[2]&&pageNumber&& pageNumber in draggableobjectlist){
        let temp=_cloneDeep(draggableobjectlist);
        temp[pageNumber]["pdf_width"]=pagesize[0];
        temp[pageNumber]["pdf_height"]=pagesize[1];
        temp[pageNumber]["scale"]=pagesize[2];
        setdraggableobjectlist(temp);
      }
      
    },[pageNumber,pagesize,pdfObject])
    function AddMenuList(){
      let li=Object.keys(icon);
      let temp=[];
      for(let i=0;i<li.length;i++){
       temp.push(
       <MenuItem onClick={() =>{add_draggableObject(li[i])}}>
        {li[i]}
      </MenuItem>)
      }
      return temp;}
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box display="flex" height="100vh" width="100%" overflow="hidden">
        <CssBaseline />
        
          <Box style={{width:"850px"}}>
              <Box width={'100%'} height={'100%'} style={{overflowY:"auto"}} >
                {PDFViewer()}
              </Box>
            </Box>
        
          <Box
            sx={{
              my: 8,
              mx: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width:'max-content',
              height:'100%',
            }}
          >
            <Box sx={{ display: 'flex',flexDirection:'row',alignContent:"center",alignItems:"center" }} >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <PictureAsPdfIcon />
            </Avatar>
            <Input type="file" onChange={handleFileChange} />
            </Box>
            {
              pdfObject?<><Box sx={{ display: 'flex',flexDirection:'row',alignContent:"center",alignItems:"center" }} >
              <Button onClick={()=>{
                if(pageNumber>1) {
                  setPageNumber(pageNumber-1)
                } 
                }}>Previous Page</Button>
              <Button onClick={()=>{
                if(pageNumber<numPages) {setPageNumber(pageNumber+1)}
                else{
                  let option={}
                  for(let i=1;i<=numPages;i++){
                    for(let j=0;j<draggableobjectlist[i]['label'].length;j++){
                        if (draggableobjectlist[i]['label'][j][0] in option){
                          if (!option[draggableobjectlist[i]['label'][j][0]].includes(draggableobjectlist[i]['label'][j][1]))
                          option[draggableobjectlist[i]['label'][j][0]].push(draggableobjectlist[i]['label'][j][1]);
                        }
                        else{
                          option[draggableobjectlist[i]['label'][j][0]]=[draggableobjectlist[i]['label'][j][1]];
                        }
                      
                    }
                  }
                  send(option,draggableobjectlist,file);
                }
                }}>{pageNumber<numPages?"Next Page":"Submit"}</Button>
              
              </Box>
              <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" flexDirection="row-reverse">
        <Button
        variant="text"
        color="primary"
        style={{ textTransform: 'none' }}
        onClick={handleToggle}
        >
        Add
      </Button>
      <Popper id="language-popper" open={open} anchorEl={anchorEl}>
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="language-menu">
              {
                  AddMenuList()
              }
              
              
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
        </Box>
        <Box width="100%" height="100%">
            <List dense={true} fullWidth={true} style={{
              minWidth:"500px"
              ,height:"100%",
              overflowY:"auto"}}>
              
                {objectlist()}
              
            </List>
            </Box>
        </>:<></>
            }
            
        
            
          </Box>
          
      </Box>
    </ThemeProvider>
  );
}