const baseurl="https://4gee6g8ffg.execute-api.ap-east-1.amazonaws.com/Fill_form"
export async function send(option,labeljson,file) {

    let reader = new FileReader();

      // Convert the file to base64 text
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Make a fileInfo Object
      console.log("Called", reader);
      let baseURL = reader.result;
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            "label": labeljson,
            "option": option,
            "file": baseURL,
            "name": file.name
        })
    };
    fetch(baseurl+'/panel', requestOptions).then(
        ()=>{alert("Success");
        window.location.reload()}
    )
    };
    
}
export async function getDefaultLabel(){
    const resp=await fetch(baseurl+"/panel");
    return resp.json();
}
export async function getCurrentLabel(file_name){
    const resp=await fetch(baseurl+"/panel/label?file_name="+file_name);
    return resp.json();
}