import logo from './logo.svg';
import './App.css';
import Side from './Side';
function App() {
  return (
    <Side/>
  );
}

export default App;
